import CssBaseline from '@mui/joy/CssBaseline'
import { CssVarsProvider } from '@mui/joy/styles'
import { APIProvider } from '@vis.gl/react-google-maps'
import AppRoutes from 'AppRoutes'
import { SnackbarProvider } from 'components/snackbar'
import theme from './theme'

const { VITE_GOOGLE_MAPS_API_KEY } = import.meta.env

function App() {
    return (
        <APIProvider
            apiKey={VITE_GOOGLE_MAPS_API_KEY}
            libraries={['places', 'geocoding']}
        >
            <CssVarsProvider
                defaultMode="system"
                disableTransitionOnChange
                theme={theme}
            >
                <CssBaseline />
                <SnackbarProvider>
                    <AppRoutes />
                </SnackbarProvider>
            </CssVarsProvider>
        </APIProvider>
    )
}

export default App
