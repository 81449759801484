import { insertIf } from '@fastre/core/src/helperFunctions/array'
import { Box, Tab, TabList, Tabs } from '@mui/joy'
import SectionHead from 'components/sectionHead'
import { useSearchParamState } from 'helperFunctions/react'
import { Fragment } from 'react'
import LinkSettings from 'settings/links'
import Automation from './automation'
import EmailTemplates from './emailTemplates'
import fundDisbursementTemplate from './fundDisbursementTemplate'
import ListingConfig from './listingConfig'
import Marketing from './marketing'
import OrgDetails from './orgDetails'
import SpecialConditionSnippets from './SpecialConditionSnippets'
import XeroInteagration from './xeroInteagration'

const tabList: [string, any][] = [
    ['Links', LinkSettings],
    //['Marketing Items', MarketingItemsSettings],
    //['Marketing Packages', MarketingSettings],
    ['Marketing', Marketing],
    //[ 'SMS Templates', SMSTemplates ],
    ['Organisation Settings', OrgDetails],
    ['Actions Settings', ListingConfig],
    ['Automation', Automation],
    ['Xero', XeroInteagration],
    ['Special Condition Snippets', SpecialConditionSnippets],
    ['Fund Disbursement Template', fundDisbursementTemplate],
    ...insertIf(import.meta.env.DEV, ['Email Templates', EmailTemplates] as any),
]

export default () => {
    const [activeTab, setActiveTab] = useSearchParamState('tab', 'Links')

    return (
        <>
            <Box
                sx={{
                    px: {
                        xs: 2,
                        sm: 4,
                    },
                }}
            >
                <SectionHead title="Settings" />
                <Tabs
                    value={activeTab}
                    onChange={(e, val) => setActiveTab(val as any)}
                    sx={{ mx: 0 }}
                >
                    <TabList>
                        {tabList.map(([label]) => (
                            <Fragment key={label}>
                                <Tab value={label}>{label}</Tab>
                            </Fragment>
                        ))}
                    </TabList>
                    <Box sx={{ py: 2 }}>
                        {tabList.map(([name, Component]) => (
                            <Box
                                key={name}
                                sx={{
                                    display: activeTab == name ? undefined : 'none',
                                }}
                            >
                                <Component />
                            </Box>
                        ))}
                    </Box>
                </Tabs>
            </Box>
        </>
    )
}
