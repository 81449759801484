import { FrontendUserSchema } from '@fastre/core/src/schemas/userMembership'
import { DialogActions, DialogContent, DialogTitle, Modal, ModalClose, ModalDialog } from '@mui/joy'
import Button from '@mui/joy/Button'
import { useApi } from 'api'
import { useUsersApi } from 'apiProviders'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { useState } from 'react'
import { useNavigate } from 'react-router'

interface RemoveUserModalProps {
    open: boolean
    user?: FrontendUserSchema
    onClose: () => void
}

export default ({ open, onClose, user }: RemoveUserModalProps) => {
    const api = useApi()
    const usersApi = useUsersApi()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    return (
        <Modal
            open={open}
            onClose={dontCloseOnBackgroundClick(onClose)}
        >
            <ModalDialog>
                <DialogTitle>
                    <ModalClose />
                    Remove User
                </DialogTitle>
                <DialogContent>
                    Are you sure you wish to remove {user?.firstName} {user?.lastName}?
                </DialogContent>
                <DialogActions>
                    <Button
                        loading={loading}
                        color="danger"
                        onClick={async () => {
                            setLoading(true)
                            await api.post(`/user/${user?.userId}/remove`)
                            await usersApi.refresh()
                            setLoading(false)
                            navigate('..')
                            onClose()
                        }}
                    >
                        Remove
                    </Button>
                    <Button
                        onClick={onClose}
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </ModalDialog>
        </Modal>
    )
}
