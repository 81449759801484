import { SpecialConditionSnippet } from '@fastre/core/src/schemas/specialConditionSnippet'
import { DeleteRounded } from '@mui/icons-material'
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    IconButton,
    Modal,
    ModalDialog,
    Stack,
    Textarea,
} from '@mui/joy'
import { useApi } from 'api'
import { useSpecialConditionSnippetsApi } from 'apiProviders'
import ButtonSheet from 'components/buttonSheet'
import Input from 'components/input'
import { dontCloseOnBackgroundClick } from 'components/modal'
import { assoc } from 'ramda'
import { useState } from 'react'

type MaybeNewSnippet = Omit<SpecialConditionSnippet, 'id'> & { id?: string }

export default () => {
    const api = useApi()
    const snippetsApi = useSpecialConditionSnippetsApi()

    const [confirmDelete, setConfirmDelete] = useState<string>()
    const [loadingDelete, setLoadingDelete] = useState<Record<string, boolean>>({})
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [selectedSnippet, setSelectedSnippet] = useState<MaybeNewSnippet>()

    return (
        <>
            <Box sx={{ minWidth: '150px', maxWidth: '400px' }}>
                <Stack gap={2}>
                    {snippetsApi.maybeData.map(snippets =>
                        snippets.map(snippet => (
                            <Box
                                sx={{ display: 'flex', gap: 2 }}
                                key={snippet.id}
                            >
                                <ButtonSheet
                                    onClick={() => setSelectedSnippet(snippet)}
                                    selected={selectedSnippet?.id == snippet.id}
                                >
                                    {snippet.snippetName}
                                </ButtonSheet>
                                <IconButton
                                    loading={loadingDelete[snippet.id]}
                                    onClick={() => setConfirmDelete(snippet.id)}
                                >
                                    <DeleteRounded />
                                </IconButton>
                            </Box>
                        )),
                    )}
                    <Box>
                        <Button
                            variant="outlined"
                            onClick={() => setSelectedSnippet({ snippetName: 'New Snippet', snippet: '' })}
                            sx={{ mt: 2 }}
                        >
                            Add Snippet
                        </Button>
                    </Box>
                </Stack>
            </Box>
            <Modal
                open={confirmDelete != undefined}
                onClose={dontCloseOnBackgroundClick(() => setConfirmDelete(undefined))}
            >
                <ModalDialog>
                    <DialogTitle>Delete Snippet</DialogTitle>
                    <DialogContent>Are you sure you want to delete this snippet?</DialogContent>
                    <DialogActions>
                        <Button
                            loading={loadingDelete[confirmDelete!]}
                            color="danger"
                            startDecorator={<DeleteRounded />}
                            onClick={async () => {
                                setLoadingDelete(assoc(confirmDelete!, true))
                                await api.post(`deletespecialconditionsnippet/${confirmDelete}/delete`, {
                                    id: confirmDelete,
                                })
                                await snippetsApi.refresh()
                                setLoadingDelete(assoc(confirmDelete!, false))
                                setConfirmDelete(undefined)
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setConfirmDelete(undefined)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
            <Modal
                open={selectedSnippet != undefined}
                onClose={dontCloseOnBackgroundClick(() => setSelectedSnippet(undefined))}
            >
                <ModalDialog sx={{ width: '100%' }}>
                    <DialogTitle>{selectedSnippet?.id ? 'Edit' : 'New'} Snippet</DialogTitle>
                    <DialogContent>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Input
                                label="Name"
                                value={selectedSnippet?.snippetName}
                                onChange={val =>
                                    setSelectedSnippet({ ...selectedSnippet!, snippetName: val })
                                }
                            />
                            <FormControl>
                                <FormLabel>Snippet</FormLabel>
                                <Textarea
                                    value={selectedSnippet?.snippet}
                                    onChange={e =>
                                        setSelectedSnippet({ ...selectedSnippet!, snippet: e.target.value })
                                    }
                                    minRows={5}
                                />
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            loading={loadingUpdate}
                            disabled={!selectedSnippet?.snippetName || !selectedSnippet?.snippet}
                            onClick={async () => {
                                setLoadingUpdate(true)
                                await api.post('updatespecialconditionsnippet', selectedSnippet)
                                await snippetsApi.refresh()
                                setSelectedSnippet(undefined)
                                setLoadingUpdate(false)
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            variant="outlined"
                            onClick={() => setSelectedSnippet(undefined)}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </ModalDialog>
            </Modal>
        </>
    )
}
