import { Box, CircularProgress } from '@mui/joy'

export default () => (
    <Box
        sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
        }}
    >
        <Box
            sx={{
                width: '100px',
                height: '100px',
                animation: 'spin 4s linear infinite',

                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-50px',
                marginLeft: '-50px',
            }}
        >
            <CircularProgress size="lg" />
        </Box>
    </Box>
)
